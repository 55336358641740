import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerObject,
} from './bannerSection.style';

import MelbourneImage from 'common/src/assets/image/saas/banner/support.jpg';

const AboutSection = ({
  row,
  col,
  title,
  description,
  buttonWrapper,
  button,
}) => {
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Support"
                  {...title}
                />
              }
              description={
                <div>
                  <Text
                    content="For support, you may:"
                    {...description}
                  />
                  <Text
                    content="1. Read our documentation."
                    {...description}
                  />
                  <Box {...buttonWrapper}>
                    <a href="https://europanb.atlassian.net/wiki/spaces/EN/pages/2162693/Europa%2BNotebooks%2BDocumentation">
                      <Button {...button}
                        title="DOCUMENTATION"
                        style={{ width: '262px' }}
                      />
                    </a>
                  </Box>
                  <br/>
                  <Text
                    content="3. Open a request with our Service Desk."
                    {...description}
                  />
                  <Box {...buttonWrapper}>
                    <a href="https://europanb.atlassian.net/servicedesk/customer/portals">
                      <Button {...button} style={{ width: '262px' }} />
                    </a>
                  </Box>
                  <br/>
                  <Text
                    content="3. Send us an email."
                    {...description}
                  />
                  <Box {...buttonWrapper}>
                    <a href="mailto:support@europanb.atlassian.net">
                      <Button {...button }
                        title="support@europanb.atlassian.net"
                        style={{ textTransform: 'lowercase', width: '262px' }}
                      />
                    </a>
                  </Box>
                </div>
              }
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={MelbourneImage} alt="Melbourne City" />
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

AboutSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

AboutSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  buttonWrapper: {
    mt: `${4}`,
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    title: 'SERVICE DESK',
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primary',
    height: `${4}`,
  },
};

export default AboutSection;
